import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import { Icon } from '@iconify/react';
import menuIcon from '@iconify/icons-iwwa/menu';

import logo from '../images/logo.png'
import arrowNorth from '../images/arrow-north.png'

import '../scss/navbar.scss';


const NavBar = (props, {data}) => {
    const [navOpen, setNavOpen] = useState(false);

    useEffect(() => {
        let resizeTimer;
        window.addEventListener("resize", () => {
          document.body.classList.add("resize-animation-stopper");
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(() => {
            document.body.classList.remove("resize-animation-stopper");
          }, 400);
        });
    }, [])

    return (
        <div className={`navbar ${props.blog ? 'blog-state' : 'two-states'}`}>
            {/* fix transition so that it collapses but doesnt use the transition when resizing */}
            <div className={`collapsed transition ${navOpen ? 'open ' : 'closed'}`} />
            <div className={`nav-content transition ${navOpen ? 'open' : 'closed'}`}>
                <Link to='/'>
                    <img alt='Northbound Logo' src={arrowNorth} className='logo2'></img>
                </Link>
                <div className='links'>
                    <Link to='/projects/films'>films</Link>
                    <Link to='/projects/brands'>brands</Link>
                    <Link to='/about'>about</Link>
                    <Link to='/contact'>contact</Link>
                    <Link to='/reel'>reel</Link>
                    {/* <Link to='/blog'>blog</Link> */}
                </div> 
            </div>
            <div className='controls'>
                <div className={`controls-container ${navOpen ? 'controls-open' : 'controls-closed'}`}>
                    <Icon icon={menuIcon} className='menu'
                        onClick={() => {
                            setNavOpen(!navOpen)
                            if (props.parentCallback)
                                props.parentCallback(!navOpen)
                        }}
                    />

                    { !navOpen && 
                        <Link to='/' className='logo1'>
                            <img alt='Northbound Logo' src={logo}></img>
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default NavBar;