import React from 'react';
import Helmet from 'react-helmet';

const Head = (props) => {
    return (
        <Helmet title='Northbound Films'>
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <link rel='canocial' href={props.url}></link>
        </Helmet>
    )
}

export default Head;